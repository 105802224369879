<template>
    <div>
        <download-csv
                v-if="$store.state.permissions.includes('project-csv')"
                class   = "btn btn-info pull-right hand"
                :data   = "csvData"
                name    = "ProjectsData.csv">

            <i class="fa fa-download"></i> CSV
        </download-csv>

        <b-row class="ml-1">
            <h3>All Projects</h3>
        </b-row>
        <div class="d-flex flex-wrap justify-content-between">
                <div
                 >
                    <label for="pagination" class="mt-0 d-block font-weight-bold font-12">Records Per Page </label>
                    <b-form-select class="hand width-select" id="pagination" :options="pageOptions" v-model="perPage"/>
                </div
                >

                <div class=""
                 >
                    <label class="mt-0 d-block font-weight-bold font-12 ">Companies</label>
                    <select2 w v-model="selected_company_id" placeholder="select company"
                             class="form-control no-border-radius select2 custom-select ">
                        <option value="all">All</option>
                        <option v-for="(company,index) in companies" :key="index" :value="company.hash_id"   :per-page="perPage" :filter="search" @filtered="onFiltered">
                            {{company.name}}
                        </option>
                    </select2>
                </div
                >
                <!--            date range start from here-->
                <div
                 >
                    <div>
                        <label class="mt-0 font-weight-bold font-12">Date range </label>
                        <div class="daterange d-flex hand" style="width:300px !important">
                          <div class="calender-range" ><i class="fa fa-calendar place"></i></div>
                            <span class="daterange-dis ml-2 font"></span>
                        </div>
                    </div>
                </div
                >
                <div
                 >
                    <label for="search" class="mt-0 font-weight-bold font-12">Search </label>
                    <b-input-group>
                        <b-form-input id="search" v-model="search" placeholder="Type to Search"/>
                        <b-input-group-append>
                            <b-btn class="hand" :disabled="!search" @click="search = ''">Clear</b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </div
                >
        </div>
        <b-row class="mt-3" v-if="selected_company_id">
            <b-col>
                <b-table small responsive :busy.sync="isBusy" show-empty empty-text="No projects found for this company"
                         striped bordered hover fixed :items="items" :fields="fields"  :current-page="currentPage"
                         :per-page="perPage" :filter="search" @filtered="onFiltered" class="break">

                    <template v-slot:cell(logo)="data">
                        <img v-if="data.value" :src="'https://api.stafftimerapp.com/storage/' + data.value" width="40px"
                             height="40px"/>
                    </template>
                    <template v-slot:cell(action)="cell" >
                        <!-- We use click.stop here to prevent a 'row-clicked' event from also happening -->
                        <b-btn size="sm" @click.stop="impersonate(cell.item.encrypted_id)">Impersonate</b-btn>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="12" class="my-1">
                        <b-pagination align="right" aria-controls="companies_table" :total-rows="totalRows"
                                      :per-page="perPage" v-model="currentPage" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
import select2 from "../components/assets/select2";
import moment from "moment";
import $ from "jquery";
import "daterangepicker/daterangepicker.js";
import "daterangepicker/daterangepicker.css";

export default {
  name: "Projects",
  components: { select2 },
  data() {
    return {
      company_id: "all",
      start: moment().subtract(1, "year"),
      end: moment(),
      start_date: moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 0,
      search: null,
      fields: [
       {
          key:'logo',
          label: "Logo",
          thStyle: {
            width: "40px"
          }
        },
        {
          key:'name',
          label: "Name",
          sortable: true,
          thStyle: {
            width: "130px"
          }
        },
       {
          key:'users',
          label: "Members",
          sortable: true,
          thStyle: {
            width: "100px"
          }
        },
       {
          key:'screenshots',
          label: "No of Screenshots",
          sortable: true,
          thStyle: {
            width: "100px"
          }
        },
        {
          key:'project_status',
          label: "Status",
          sortable: true,
          thStyle: {
            width: "60px"
          }
        },
        {
          key:'start_date',
          label: "Start Date",
          sortable: true,
          thStyle: {
            width: "100px"
          }
        }
      ]
    };
  },

  computed: {
    ...mapState(["projects"]),
    csvData() {
      return this.$store.state.projects.map((item, index) => ({
        Index: index + 1,
        Name: item.name,
        "Start Date": item.start_date,
        "Screen Shots": item.screenshots,
        "Project Status": item.project_status,
        Users: item.users,
        "Company Name": item.company_name
      }));
    },
    items() {
      return this.$store.state.projects;
    },
    isBusy() {
      return this.$store.state.isBusy;
    },
    // selected_company_id() {
    //     return this.$store.state.selected_company_id;
    // },

    companies() {
      return this.$store.state.all_companies;
    },
    selected_company_id: {
      get() {
        return this.$store.state.selected_company_id;
      },
      set(value) {
        this.$store.commit("set_selected_company_id", value);
      }
    },
    selectedCompany() {
      return this.companies.find(
        company => parseInt(company.id) === parseInt(this.selected_company_id)
      );
    }
  },
  methods: {
    impersonate(id) {
      let url = "http://backoffice-test.stafftimerapp.com/user-login/" + id;
      let win = window.open(url, "_blank");
      win.focus();
    },
    get_projects(company) {
      this.$store.dispatch("getProjects", company);
    },
    dateRangeStart() {
      $(".daterange span").html(
        this.start.format("MMM D, YYYY") +
          " - " +
          this.end.format("MMM D, YYYY")
      );
      $(".daterange").daterangepicker(
        {
          startDate: this.start,
          endDate: this.end,
          opens: "left",
          ranges: {
            "Last Year": [moment().subtract(1, "year"), moment()],
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ]
          }
        },
        this.dateRangeChange
      );
    },
    dateRangeChange(start, end) {
      $(".daterange span")
        .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
        .addClass("pl-2 pr-1");
      this.start_date = start.format("YYYY-MM-DD");
      this.end_date = end.format("YYYY-MM-DD");

      this.getDashboardData();
    },
    getDashboardData() {
      this.$store.commit("toggle_loader", true);
      if (this.selected_company_id == null) {
        this.selected_company_id = "all";
      }
      let data = {
        from: this.start_date,
        to: this.end_date,
        company_id: this.selected_company_id
      };
      this.$store.dispatch("getProjects", data).then(() => {
        this.$store.commit("toggle_loader", false);
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  watch: {
    selected_company_id: function(id) {
      let data = {
        from: this.start_date,
        to: this.end_date,
        company_id: id
      };
      this.get_projects(data);
    }
  },
  created() {
    this.$store.dispatch("getAllCompanies");
  },
  mounted() {
    this.getDashboardData();
    this.dateRangeStart();
    this.selected_company_id = "all";
  }
};
</script>

<style scoped>
.width-select{
    min-width: 130px!important;
}
</style>
<style>
    /* .select2-container {
        display: block !important;
        width: 100% !important;
    } */
</style>
